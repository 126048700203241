@keyframes loadbar {
    0% { left: 0; right: 100%; }
    25% { left: 0; right: 15%; }
    100% { left: 100%; right: 0; }
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&display=swap');

* { outline: unset !important; }

html, body { width: 100%; height: 100%; }

body {
    font-family: 'Barlow', sans-serif;
    background-color: #000;
    color: #fff;
    text-align: center;
}

.btn {
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 1rem;
    text-transform: uppercase;
    background: linear-gradient(270deg, #FAB818, #F47521);
    height: 50px;
    width: 200px;
    display: block;
    margin: auto;
    padding: 1rem;
    border-radius: 25px;

    &:hover, &.dark {
        background: linear-gradient(270deg, #F47521, #EF4323);
        color: #fff;
    }

    &.dark:hover { background: #000; }

    &:focus { box-shadow: 0 0 0 .25rem rgba(244, 117, 33, 0.25); }

    &:disabled, &.disabled {
        position: relative;
        opacity: .75;
        pointer-events: none;
        color: #fff;
        overflow: hidden;
        
        &:not(.notLoading) {
            cursor: progress;

            &:after {
                content: '';
                position: absolute;
                bottom: 3px;
                height: 4px;
                background-color: #fff;
                border-radius: 4px;
                animation: loadbar 1s ease-out infinite;
            }
        }
    }
}

.leftSide {
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    img {
        width: 100%;
        max-width: 275px;
        display: block;
        margin: auto;

        &.crunch {
            max-width: 210px;
            margin-bottom: .5rem;
        }

        &.entel { max-width: 80px; }
    }

    &>h1 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 1rem;
    }

    &>h6 {
        font-size: 14px;
        font-weight: 400;
    }

    .plans {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 1.5rem 0;
        text-align: left;
        gap: 10px;

        .plan {
            background-color: #131313;
            box-shadow: 0 0 0 1px #fab818 inset;
            padding: 2rem .75rem 1.5rem;
            width: calc(50% - 5px);
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            text-align: center;

            &:before {
                content: '';
                position: absolute;
                top: 10px;
                left: 10px;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                border: 2px solid #ededed;
            }

            &.select {
                border-color: #f47521;
                box-shadow: 0 0 0 3px #f47521 inset;

                &:before {
                    background-color: #fff;
                    border: 6px solid #f47521;
                }
            }

            h2 {
                font-size: 24px;
                font-weight: 700;
                margin: 1rem 0;
            }

            h3 {
                font-size: 20px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0;
            }

            h6 {
                font-size: 12px;
                font-weight: 400;
            }

            ul {
                margin-block-start: 0;
                margin-block-end: 0;
                padding-inline-start: 20px;
                list-style: none;
                text-align: left;

                li {
                    font-size: 14px;
                    font-weight: 400;

                    &:not(:last-child) { margin-bottom: 1rem; }

                    img {
                        display: inline-block;
                        vertical-align: top;
                        margin-top: .25rem;
                        margin-left: -20px;
                        width: 12px;
                        height: 12px;
                        margin-right: .5rem;
                    }

                    &.disabled { opacity: .2; }
                }
            }
        }
    }

    form {
        width: 100%;
        max-width: 275px;
        margin: 0 auto;

        .inpField {
            display: flex;
            height: 50px;
            align-items: center;
            background-color: #fff;
            border-radius: 8px;
            margin-bottom: 10px;
            position: relative;

            img {
                display: block;
                width: 16px;
                margin: 0 1rem;
            }

            input {
                display: block;
                width: 100%;
                border: none;
                background: none;
                font-size: 18px;
                font-weight: 600;
                height: 30px;
                border-left: 1px solid #3b3b3b;
                padding: 0 1rem;
            }

            &.invalid {
                input { color: red; }

                &:after {
                    position: absolute;
                    white-space: nowrap;
                    content: attr(data-invalid);
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: red;
                    color: white;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 1;
                    padding: .25rem 1rem;
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }
}

.rightSideImg {
    display: none;
    vertical-align: middle;
    width: 50%;
}

#mostSeen {
    background: linear-gradient(195deg, #FAB818 0%, #F47521 100%);

    h1 {
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 2rem;
    }

    .carousel {
        margin-bottom: 3rem;

        .slide {
            img {
                width:  260px;
                max-width: 100%;
                margin: 0 auto;
            }

            h6 {
                font-size: 14px;
                font-weight: 700;
                white-space: pre-wrap;
            }
        }
        
        .slick-dots {

            li {
                margin: 0;

                button:before {
                    color: #fff;
                    font-size: 8px;
                    opacity: 1;
                }

                &.slick-active {
                    button:before {
                        color: #EF4323;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

#faq {
    background-color: #fff;
    color: #59595B;

    h2 {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 1.5rem;
    }

    .question {
        border: 1px solid #ddd;
        border-radius: 8px;
        text-align: left;
        max-width: 1000px;
        margin: 0 auto .5rem;

        h4 {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            padding: 1rem;
            position: relative;
            cursor: pointer;

            img {
                position: absolute;
                width: 20px;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%) rotate(180deg);
            }

            &.open {
                img { transform: translateY(-50%) rotate(0deg); }

                & + .information { display: block; }
            }
        }

        .information {
            font-size: 12px;
            padding: 1rem;
            border-top: inherit;
            display: none;

            ul {
                margin-block-start: 0;
                margin-block-end: 0;
                padding-inline-start: 20px;
            }
        }
    }
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    padding: 1.5rem;
    overflow: auto;

    .popbox {
        background-color: #fff;
        width: 100%;
        max-width: 320px;
        margin: auto;
        border-radius: 20px;
        padding: calc(2rem + 30px) 1.5rem 2.5rem;
        color: #213944;
        position: relative;

        .closeBtn {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
        }

        .banner {
            display: inline-block;
            width: 150px;
        }

        h2 {
            color: #f47521;
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        p { font-size: 14px; }

        h6 {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 2rem;
        }
    }
}

footer {
    font-size: 10px;
    font-weight: 400;
    text-align: left;
}

@media (min-width: 845px) {
    .leftSide {
        width: 50%;

        img {
            &.banner { display: none; }
            &.crunch { max-width: 330px; }
            &.entel { max-width: 160px; }
        }

        &>h1 {
            margin-top: 2rem;
            font-size: 36px;
        }

        &>h6 { font-size: 24px; }        

        .plans .plan {
            h2, h3 { font-size: 32px; }
            h6 { font-size: 20px; }

            ul li {
                font-size: 16px;
                img { margin-top: .45rem; }
            }
        }

        form {
            max-width: 400px;
            margin-top: 3rem;

            .inpField { margin-bottom: 20px; }
        }
    }

    .rightSideImg { display: inline-block; }

    #mostSeen {
        h1 { font-size: 40px; }
    }

    #faq {
        h2 { font-size: 34px; }

        .question {
            h4 { font-size: 18px; }
            .information { font-size: 14px; }
        }
    }
}


.mat-dialog-container {
    border-radius: 20px !important;
    padding: 0px !important;
  }
  
  .mat-dialog-actions[align=end] {
    justify-content: center;
    justify-content: space-around;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  
  .close-button {
    border-radius: 50%;
    border: 1px solid #F47521;
    color: #F47521;
    font-size: 15px;
    background-color: transparent;
  }
  
  .topright {
    float: right;
    z-index: 1;
  }
  
  .custom-dialog-container{
  padding: 20px !important;
  border-radius: 20px !important;
  width: 80%;
  height: auto;
  max-height: 85vh;
  overflow-y:scroll; 
  text-align: left;
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #000;
  left: 50%;
  top: 50%
  }
  
.form-checkbox{
    font-size: 12px;
    text-align: left;
    margin: 10px 0px;
}